<template>
  <div class="body">
    <div class="head">
      <Head></Head>
    </div>
    <img src="@/image/biaozhunhua/biaozhunhua.jpg" alt="" />
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "shiiyanshi",
  components: { Head, Foot },
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.head {
  position: sticky;
  top: 0px;
  z-index: 99999999;
}

img {
  width: 75%;
  //height: 100%;
}
.prize-title-bg3 {
  padding: 5vh 19vw;
  margin-top: 10vh;
}
</style>
